<template>
    <div>
        <div class="card d-flex p-3 m-0 mb-0" style="height: calc(100dvh - 7rem)">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-4" v-loading="isProccessing">
                        <div class=" mb-2">
                            <h5><strong class="text-info">Push notification</strong></h5>
                        </div>
                        <VuePerfectScrollbar style="height: calc(100dvh - 12rem)" :settings="{ suppressScrollX : true }">
                            <div class="flex-fill">
                                <div class=" mb-2">
                                    <div class="mb-1"><small><b>Special or Group</b></small></div>
                                    <el-select v-model="specialOrGroup" placeholder="Select" class="w-100">
                                        <el-option label="All" :value="parseInt(0)" />
                                        <el-option label="Special users" :value="parseInt(1)" />
                                        <el-option label="Group user" :value="parseInt(2)" />
                                    </el-select>
                                </div>
                                <div class=" mb-2" v-if="specialOrGroup === 1">
                                    <div class="mb-1"><small><b>Pick users to send</b></small></div>
                                    <div>
                                        <el-tag :key="tag.usernameOrEmail" class="mb-2"
                                                v-for="tag in listUserSpecialTarget"
                                                closable
                                                :disable-transitions="false"
                                                @close="handleClose(tag.usernameOrEmail)">
                                            {{tag.usernameOrEmail}}
                                        </el-tag>
                                        <div>
                                            <el-input class="input-new-tag"
                                                      v-if="userInputForSpecialVisible"
                                                      v-model="userInputForSpecial"
                                                      ref="saveTagInput"
                                                      size="mini"
                                                      placeholder="Enter username or email..."
                                                      @keyup.enter.native="handleInputConfirm"
                                                      @blur="handleInputConfirm">
                                            </el-input>
                                            <el-button v-else class="button-new-tag"
                                                       size="small" @click="showInput">+ Add user</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="specialOrGroup === 2">
                                    <div class=" mb-2">
                                        <div class="mb-1"><small><b>User group</b></small></div>
                                        <el-select v-model="userGroupSelected" placeholder="Select" class="w-100" multiple>
                                            <el-option v-for="item in userTagClassifyList"
                                                       :key="item.id"
                                                       :label="item.classifyName"
                                                       :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <hr class="mt-3 mb-2" />
                                <div class="mb-2">
                                    <div class="mb-1"><small><b>Notification title</b></small></div>
                                    <el-input placeholder="Please input title"
                                              v-model="notificationTitle"
                                              clearable>
                                    </el-input>
                                </div>
                                <div class="mb-2">
                                    <div class="mb-1"><small><b>Notification description</b></small></div>
                                    <el-input placeholder="Please input description"
                                              v-model="notificationDescription"
                                              type="textarea" :rows="3">
                                    </el-input>
                                </div>
                                <div class="mb-2">
                                    <div class="mb-1"><small><b>Notification image</b></small></div>
                                    <div class="d-flex">
                                        <div class="flex-fill">
                                            <el-input placeholder="Please input image url"
                                                      v-model="notificationImageUrl"
                                                      id="imageSelectedDom"
                                                      clearable>
                                            </el-input>
                                        </div>
                                        <div class="ml-2">
                                            <el-button @click="selectFileWithCKFinder('imageSelectedDom')">Select</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="mb-1"><small><b>Notification hyperlink (optional)</b></small></div>
                                    <div class="d-flex">
                                        <div class="flex-fill">
                                            <el-input placeholder="Please input hyperlink url"
                                                      v-model="notificationHyperlinkUrl"
                                                      clearable>
                                            </el-input>
                                        </div>
                                    </div>
                                </div>
                                <div class=" mb-3">
                                    <el-button type="primary" :disabled="!isContinue" @click="sendNotification">Send notification</el-button>
                                    <el-button @click="resetForm">Reset</el-button>
                                </div>
                                <hr class="mt-3 mb-2 d-none d-md-block" />
                                <div class="mb-2 w-75 d-none d-md-block">
                                    <div>
                                        <div class="mb-1"><small><b>Preview</b></small></div>
                                        <el-card :body-style="{ padding: '1rem' }">
                                            <img :src="notificationImageUrl ? notificationImageUrl : `https://crmviet.vn/wp-content/uploads/2019/05/promotion-la-gi.jpg`"
                                                 onerror="this.src ='https://crmviet.vn/wp-content/uploads/2019/05/promotion-la-gi.jpg'"
                                                 class="image w-100 mb-1">
                                            <div>
                                                <small v-if="notificationTitle"><strong>{{notificationTitle}}</strong></small>
                                                <small v-else><strong>Notification title</strong></small>
                                            </div>
                                            <div>
                                                <small v-if="notificationDescription">
                                                    {{notificationDescription}}
                                                </small>
                                                <small v-else>
                                                    Notification description
                                                </small>
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                            </div>
                        </VuePerfectScrollbar><!--//page-content-->
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="flex-fill d-flex flex-column" style="height: calc(100dvh - 10rem)">
                            <div class="flex-fill" id="ckEditorArea">
                                <!--<ckeditor v-model="notificationContent" :config="editorConfig"
                                          @namespaceloaded="onNamespaceLoaded"
                                          @ready="onEditorReady" editorUrl="https://cdn.ckeditor.com/4.16.2/full-all/ckeditor.js" />-->

                                <editor ref="tinyEditorRef" api-key="rpx7xxe5bm84bmdel9q9fgax52k275604ix6ytrf2l4k1adn"
                                        :init="tinyEditorInit" @onInit="tinyEditorShow"
                                        v-model="notificationContent"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--//container-->
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
    import usersApi from "@/api/common/users";
    import notificationApi from "@/api/common/notification";
    import baseComponent from "@/scripts/baseComponent";
    import commonAct from '@/commonActionHandle.js';
    import { editorConfig, tinyEditorInit } from '@/constant/config';
    import Editor from '@tinymce/tinymce-vue';
    export default {
        extends: baseComponent,
        components: {
            'editor': Editor
        },
        data() {
            return {
                isProccessing: false,
                isSending: false,
                specialOrGroup: 1,
                userGroupSelected: [],
                userTypeSelected: null,
                listUserSpecialTarget: [],
                userInputForSpecialVisible: false,
                userInputForSpecial: '',
                editor: ClassicEditor,
                notificationImageUrl: '',
                notificationHyperlinkUrl: '',
                notificationTitle: '',
                notificationDescription: '',
                notificationContent: '',
                editorConfig: editorConfig,
                tinyEditorInit: {
                    ...tinyEditorInit,
                    height: $("#ckEditorArea").height(),
                    file_picker_callback: function (callback, value, meta) {
                        /* Provide file and text for the link dialog */
                        if (meta.filetype === 'file') {
                            window.selectFileWithCKFinder((fileUrl) => {
                                callback(fileUrl, { text: 'iRender Vietnam JSC' });
                            });
                        }

                        /* Provide image and alt text for the image dialog */
                        if (meta.filetype === 'image') {
                            window.selectFileWithCKFinder((fileUrl) => {
                                callback(fileUrl, { alt: 'iRender Vietnam JSC' });
                            });
                        }

                        /* Provide alternative source and posted for the media dialog */
                        if (meta.filetype === 'media') {
                            window.selectFileWithCKFinder((fileUrl) => {
                                callback(fileUrl, { text: 'iRender Vietnam JSC' });
                            });
                        }
                    }
                },
            };
        },
        watch: {
            specialOrGroup() {
                //this.listUserSpecialTarget = [];
            }
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
                decentralization: (state) => state.auth.decentralization,
                userTagClassifyList: state => state.common.userClassifyAll
            }),
            isContinue() {
                if (this.specialOrGroup === 1) {
                    return this.listUserSpecialTarget.length > 0 && this.notificationImageUrl && this.notificationTitle && this.notificationDescription
                                && (this.notificationContent || this.notificationHyperlinkUrl);
                }
                else {
                    return this.notificationImageUrl && this.notificationTitle && this.notificationDescription && (this.notificationContent || this.notificationHyperlinkUrl);
                }
            }
        },
        mounted() {
            this.getClassify();
        },
        methods: {
            getClassify() {
                this.$store.dispatch("common/getAllClassify");
            },
            onEditorReady(editor) {
                setTimeout(() => {
                    $("#ckEditorArea .cke_contents").height($("#ckEditorArea").height() - $('#ckEditorArea .cke_top').first().height() - $('#ckEditorArea .cke_bottom').first().height() - 50);
                }, 100);
                
            },
            onNamespaceLoaded( CKEDITOR ) {
                // Add external `placeholder` plugin which will be available for each
                // editor instance on the page.

                CKEDITOR.plugins.addExternal('placeholder', '/ckeditor-plugins/plugins/placeholder', 'plugin.js');
                CKEDITOR.plugins.addExternal('colorbutton', '/ckeditor-plugins/plugins/colorbutton', 'plugin.js');
                CKEDITOR.plugins.addExternal('divarea', '/ckeditor-plugins/plugins/divarea', 'plugin.js');
                CKEDITOR.plugins.addExternal('editorplaceholder', '/ckeditor-plugins/plugins/editorplaceholder', 'plugin.js');
                CKEDITOR.plugins.addExternal('emoji', '/ckeditor-plugins/plugins/emoji', 'plugin.js');
                CKEDITOR.plugins.addExternal('justify', '/ckeditor-plugins/plugins/justify', 'plugin.js');
                CKEDITOR.plugins.addExternal('panelbutton', '/ckeditor-plugins/plugins/panelbutton', 'plugin.js');
                CKEDITOR.plugins.addExternal('autolink', '/ckeditor-plugins/plugins/autolink', 'plugin.js');
                CKEDITOR.plugins.addExternal('button', '/ckeditor-plugins/plugins/button', 'plugin.js');
                CKEDITOR.plugins.addExternal('codesnippet', '/ckeditor-plugins/plugins/codesnippet', 'plugin.js');
                CKEDITOR.plugins.addExternal('easyimage', '/ckeditor-plugins/plugins/easyimage', 'plugin.js');
                CKEDITOR.plugins.addExternal('embed', '/ckeditor-plugins/plugins/embed', 'plugin.js');
                CKEDITOR.plugins.addExternal('font', '/ckeditor-plugins/plugins/font', 'plugin.js');
                CKEDITOR.plugins.addExternal('image2', '/ckeditor-plugins/plugins/image2', 'plugin.js');
                CKEDITOR.plugins.addExternal('tableresize', '/ckeditor-plugins/plugins/tableresize', 'plugin.js');
            },
            tinyEditorShow() {
                $("#ckEditorArea .tox.tox-tinymce").height($("#ckEditorArea").height());
            },
            selectFileWithCKFinder(elementId) {
                window.selectFileWithCKFinder((fileUrl) => {
                    this.$set(this, "notificationImageUrl", fileUrl);
                });
            },
            handleClose(tag) {
                this.listUserSpecialTarget.splice(this.listUserSpecialTarget.findIndex(x => x.usernameOrEmail === tag), 1);
            },

            showInput() {
                this.userInputForSpecialVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                let userInputForSpecial = this.userInputForSpecial;
                if (userInputForSpecial) {
                    this.tryAddUserToList(userInputForSpecial);
                } else {                    
                    this.userInputForSpecialVisible = false;
                    this.userInputForSpecial = '';
                }
            },
            tryAddUserToList(usernameOrEmail) {
                this.isProccessing = true;
                usersApi.getUserDetailByUsername(usernameOrEmail).then((response) => {
                    if (response.data && response.data.result === 0) {
                        this.listUserSpecialTarget.push({ usernameOrEmail: usernameOrEmail, userId: response.data.data.id });
                        //console.log(response.data);
                    } else {
                        if (response.data.message !== null && response.data.message !== "")
                        {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                    this.userInputForSpecialVisible = false;
                    this.userInputForSpecial = ''
                    this.isProccessing = false;
                }).catch((error) => {
                    console.error(error);
                    this.userInputForSpecialVisible = false;
                    this.userInputForSpecial = '';
                    this.isProccessing = false;
                });
            },
            resetForm() {
                this.userInputForSpecial = '';
                this.listUserSpecialTarget = [];
                this.notificationImageUrl = '';
                this.notificationTitle = '';
                this.notificationDescription = '';
                this.notificationContent = '';
                this.userTypeSelected = null;
            },
            sendNotification() {
                commonAct.showConfirm(
                    `Send notification to targeted user ?`,
                    () => {
                        this.isSending = true;
                        notificationApi.sendNotificationManual({
                            groupOrSpecialTarget: this.specialOrGroup,
                            userSpecialSelected: this.listUserSpecialTarget.map(x => x.userId),
                            groupUserSelected: this.userGroupSelected,
                            notificationTitle: this.notificationTitle,
                            notificationDescription: this.notificationDescription,
                            notificationContent: this.notificationContent,
                            notificationImageUrl: this.notificationImageUrl,
                            notificationHyperlinkUrl: this.notificationHyperlinkUrl,
                        }).then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.showSuccessToast(
                                    "Send notification to targeted user successfully"
                                );
                            } else {
                                if (response.data.message !== null && response.data.message !== "") {
                                    commonAct.showError(response.data.message || this.$lang.common.error);
                                }
                            }
                            this.isSending = false;
                        }).catch((error) => {
                            console.error(error);
                            this.isSending = false;
                        });
                    }
                );
                
            }
        }
    }
</script>

<style>
    html,
    body {
        overflow: hidden !important;
    }

    .el-tag {
        margin-right: 10px;
    }

    .button-new-tag {
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        vertical-align: bottom;
    }

    .input-new-tag.el-input--mini .el-input__inner {
        height: 32px !important;
        line-height: 28px;
    }
</style>